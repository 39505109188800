/* @flow */
import React from 'react';
import smartlookClient from 'smartlook-client';

import { userNamesSelector, userGUIDSelector, userIdSelector } from 'domain/env';
import { connect } from 'react-redux';

type Props = {
  userId: string,
  userEmail: string,
  userName: {|
    firstName: string,
    lastName: string,
  |},
};

class SmartLook extends React.Component<Props, {}> {
  componentDidMount() {
    document.body.dataset.sl = 'canvas-mq';
    this.init();
    this.update();
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    return nextProps.userId !== this.props.userId;
  }

  componentDidUpdate() {
    this.update();
  }

  get appId() {
    return process.env.REACT_APP_SMART_LOOK_ID;
  }

  init = () => {
    smartlookClient.init(this.appId);
    smartlookClient.record({ forms: true, numbers: true, emails: true, ips: true });
  };

  update = () => {
    const { userId, userEmail, userName } = this.props;
    if (userId) {
      smartlookClient.identify(userId, {
        name: `${userName.firstName} ${userName.lastName}`,
        email: userEmail,
      });
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  userId: userGUIDSelector(state),
  userEmail: userIdSelector(state),
  userName: userNamesSelector(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SmartLook);
